import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Fade from 'react-reveal/Fade';
import Layout from '../components/layout';
import './members.scss';

const FilmCoconShell = () => (
  <Layout className="single-film">
    <section style={{ paddingTop: 60 }}>
      <Container>
          <Row>
            <Col xs="12" md="5" className="mb-4">
              <div className="text-center">
                <Fade>
                <img loading="lazy" src="/files/hinh-hien.jpg" alt="HUỲNH PHƯỢNG HIỀN "/>
                </Fade>
              </div>
            </Col>
            <Col xs="12" md="7">
              <Fade>
              <h2>
                HUỲNH PHƯỢNG HIỀN 
              </h2>
              <div className="f-18">
                <p>
                Huỳnh Phượng Hiền is a Vietnamese filmmaker, line producer, and production designer at JK Film. Since 2014, she has been one of the founding members of JK Film Company, which produces short films such as "The Mute" (2018), "Stay Awake, Be Ready" (2019), and the first feature film "Inside the Yellow Cocoon Shell" (2023).
                </p>
                <p>
                Huỳnh Phượng Hiền served as the line producer and production designer for "Inside the Yellow Cocoon Shell" by Phạm Thiên Ân, which made its world premiere in the Directors' Fortnight section at the 76th Cannes Film Festival in 2023 and won the Caméra d'Or, awarded for the best first feature film. The film also won the Grand Prize at Tokyo FILMeX, the Roberto Rossellini Best Film Award at the Pingyao International Film Festival, the Young Cinema Award at the Asia Pacific Screen Awards, and the Silver Screen Award for Best Asian Feature Film at the Singapore International Film Festival.
                </p>
                <p>
                She also worked as the production designer and line producer for two short films: "The Mute," which had its world premiere at the Palm Springs International ShortFest in the USA, and "Stay Awake, Be Ready," which premiered in the Directors' Fortnight and won the Illy Award by Phạm Thiên Ân.
                </p>
              </div>
              </Fade>
            </Col>
          </Row>
          <hr />
          <div>
            <Row>
              <Col xs="12" md="7">
              <Fade>
                <h2>
                PHẠM THIÊN ÂN 
                </h2>
                <div className="f-18">
                  <p>
                  Phạm Thiên Ân is a Vietnamese film director and screenwriter. After graduating in Information Technology, he realized his interest in cinematography and filmmaking by teaching himself how to shoot and edit films. He is known for directing and screenwriting the feature film "Inside the Yellow Cocoon Shell" (2023).
                  </p>
                  <p>
                  His debut feature film, "Inside the Yellow Cocoon Shell," had its world premiere in the Directors' Fortnight section at the 76th Cannes Film Festival in 2023 and won the Caméra d'Or, awarded for the best first feature film. The film also won the Grand Prize at Tokyo FILMeX, the Roberto Rossellini Best Film Award at the Pingyao International Film Festival, the Young Cinema Award at the Asia Pacific Screen Awards, and the Silver Screen Award for Best Asian Feature Film at the Singapore International Film Festival. Additionally, it was voted one of the "50 Best Films of 2023" by Sight and Sound Magazine.
                  </p>
                  <p>
                  He was also the director and writer of two short films: "The Mute," which has traveled to the Palm Springs, Tampere, and Uppsala film festivals, and "Stay Awake, Be Ready," which won the Illy Prize at Directors' Fortnight in 2019 and was screened at numerous festivals such as Clermont-Ferrand, Locarno Open Doors, and Busan, winning several other awards.
                  </p>
                </div>
                </Fade>
              </Col>
              <Col xs="12" md="5" className="mb-4">
                <div className="text-center">
                  <Fade>
                  <img loading="lazy" src="/files/hinh-an.jpg" alt="PHẠM THIÊN ÂN "/>
                  </Fade>
                </div>
              </Col>
            </Row>
          </div>
          <hr />
          <div>
            <Row>
              <Col xs="12" md="5" className="mb-4">
                <div className="text-center">
                  <Fade>
                  <img loading="lazy" src="/files/hinh-thi.jpg" alt="TRẦN VĂN THI"/>
                  </Fade>
                </div>
              </Col>
              <Col xs="12" md="7">
                <Fade>
                <h2>
                TRẦN VĂN THI
                </h2>
                <div className="f-18">
                  <p>
                  Trần Văn Thi is an independent Vietnamese producer and co-founder of JK Films, a production company in Vietnam.
                  </p>
                  <p>
                  He started his career in film in 2018 by collaborating with Phạm Thiên Ân on the short film "The Mute," which had its world premiere at the Palm Springs International ShortFest in the USA. In 2019, Trần Văn Thi continued to have another successful collaboration with Phạm Thiên Ân on the short film "Stay Awake, Be Ready." It won the Illy Prize at Directors' Fortnight 2019 and was screened at numerous festivals such as Clermont-Ferrand, Locarno Open Doors, and Busan, winning several other awards.
                  </p>
                  <p>
                  After completing the short film "Stay Awake, Be Ready" in 2019, he began looking for funding in Vietnam and produced the first feature film "Inside the Yellow Cocoon Shell" by Phạm Thiên Ân, which made its world premiere in the Directors' Fortnight section at the 76th Cannes Film Festival in 2023 and won the Caméra d'Or, awarded for the best first feature film. The film also won the Grand Prize at Tokyo FILMeX, the Roberto Rossellini Best Film Award at the Pingyao International Film Festival, the Young Cinema Award at the Asia Pacific Screen Awards, and the Silver Screen Award for Best Asian Feature Film at the Singapore International Film Festival.
                  </p>
                </div>
                </Fade>
              </Col>
            </Row>
          </div>
          <hr />
          <div style={{ paddingBottom: 100 }}>
            <Row>
              <Col xs="12" md="7">
                <Fade>
                <h2>
                ĐINH DUY HƯNG 
                </h2>
                <div className="f-18">
                  <p>
                  Đinh Duy Hưng is a Vietnamese filmmaker and director of photography.
                  </p>
                  <p>
                  He is known as the director of photography for "Inside the Yellow Cocoon Shell" by Phạm Thiên Ân, which made its world premiere in the Directors' Fortnight section at the 76th Cannes Film Festival in 2023 and won the Caméra d'Or, awarded for the best first feature film. The film also won the Grand Prize at Tokyo FILMeX, the Roberto Rossellini Best Film Award at the Pingyao International Film Festival, the Young Cinema Award at the Asia Pacific Screen Awards, and the Silver Screen Award for Best Asian Feature Film at the Singapore International Film Festival. For this film, he was nominated for Best Cinematography by the International Cinephile Society in 2023 and won Runner-up.
                  </p>
                  <p>
                  He was also the director of photography for two short films: "The Mute," which had its world premiere at the Palm Springs International ShortFest in the USA, and "Stay Awake, Be Ready," which had its world premiere in Directors' Fortnight and won the Illy Award by Phạm Thiên Ân.
                  </p>
                </div>
                </Fade>
              </Col>
              <Col xs="12" md="5" className="mb-4">
                <div className="text-center">
                  <Fade>
                  <img loading="lazy" src="/files/hung.jpg" alt="ĐINH DUY HƯNG "/>
                  </Fade>
                </div>
              </Col>
            </Row>
          </div>
      </Container>
    </section>
  </Layout>
);

export default FilmCoconShell;
